@font-face {
  font-family: 'byebye-mrx';
  src:  url('../fonts/byebye-mrx.eot?afy8te');
  src:  url('../fonts/byebye-mrx.eot?afy8te#iefix') format('embedded-opentype'),
    url('../fonts/byebye-mrx.ttf?afy8te') format('truetype'),
    url('../fonts/byebye-mrx.woff?afy8te') format('woff'),
    url('../fonts/byebye-mrx.svg?afy8te#byebye-mrx') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'byebye-mrx' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-BTC:before {
  content: "\e900";
}
.icon-Bitcoin:before {
  content: "\e900";
}
.icon-BCH:before {
  content: "\e902";
}
.icon-BCC:before {
  content: "\e903";
}
.icon-DCT:before {
  content: "\e904";
}
.icon-DOG:before {
  content: "\e976";
}
.icon-Dogecoin:before {
  content: "\e976";
}
.icon-ETH:before {
  content: "\e97b";
}
.icon-Ethereum:before {
  content: "\e97b";
}
.icon-LTC:before {
  content: "\e996";
}
.icon-Litecoin:before {
  content: "\e996";
}
.icon-STR:before {
  content: "\e9d4";
}
.icon-Stellar:before {
  content: "\e9d4";
}
.icon-XLM:before {
  content: "\e9d5";
}
.icon-XMR:before {
  content: "\e9f2";
}
.icon-XRP:before {
  content: "\e9f5";
}
.icon-Ripple:before {
  content: "\e9f5";
}
.icon-USD:before {
  content: "\e9fd";
}
.icon-USDT:before {
  content: "\e9fe";
}
.icon-JPY:before {
  content: "\e9ff";
}
.icon-ZEC:before {
  content: "\ea23";
}
.icon-REP:before {
  content: "\e901";
}
.icon-Augur:before {
  content: "\e901";
}
.icon-ETC:before {
  content: "\e906";
}
.icon-EthereumClassic:before {
  content: "\e906";
}
.icon-cross:before {
  content: "\2716";
}

@import 'lib/foundation/scss/foundation';

// Global
// ---------

// Colors
// ---------
$black: #0A0A0A;
$white: #FEFEFE;
$near-white:  #F2F2F2;
$dirty-white:  #F8F7F9;
$gray:  #262626;
$dashed-gray:  #6D6D6D;
$line-gray: #4D4D4D;
$light-gray: #E6E6E6;
$medium-gray: #CACACA;
$dark-gray: #191A1A;
$green:  #40CD71;
$red:  #DE0707;
$yellow:  #FFAE00;
$orange:  #FF5833;

$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$foundation-palette: (
  primary: $orange,
  secondary: $line-gray,
  success: $green,
  warning: $yellow,
  alert: $red,
);
$body-background:  $white;
$body-font-color:  $medium-gray;
$body-font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-text-direction: ltr;
$global-flexbox: false;
$print-transparent-backgrounds: true;


// Forms
// ---------

$form-spacing: rem-calc(16);
$form-label-color: $white;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$helptext-color: $dirty-white;
$select-background: transparent;
$select-triangle-color: $dark-gray;
$select-radius: $global-radius;
$input-color: white;
$input-placeholder-color: $line-gray;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-background: transparent;
$input-background-focus: transparent;
$input-background-disabled: none;
$input-border: none;
$input-border-focus: none;
$input-shadow: none;
$input-shadow-focus: none;
$input-cursor-disabled: not-allowed;
$input-transition: border-color 0.25s ease-in-out;
$input-number-spinners: true;


// Button
// ----------

$button-padding: 0.85em 1em;
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $orange;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: $white;
$button-color-alt: $black;
$button-radius: $global-radius;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 0.9rem,
  large: 1.25rem,
);
$button-opacity-disabled: 0.25;

// Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1rem;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;

// Base Typography
// ------------------

$header-font-family: $body-font-family;
$header-font-weight: 200;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace, "Helvetica Neue", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$header-sizes: (
  small: (
    'h1': 22,
    'h2': 13,
    'h3': 20,
    'h4': 16,
    'h5': 14,
    'h6': 12,
  ),
  medium: (
    'h1': 25,
    'h2': 14,
    'h3': 20,
    'h4': 16,
    'h5': 14,
    'h6': 12,
  ),
);
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.5;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $body-font-color;
$code-font-family: $body-font-family;
$code-font-weight: $global-weight-normal;
$code-background: $dirty-white;
$code-border: 1px solid white;
$code-padding: rem-calc(6 5);
$anchor-color: $white;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid white;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: none;
$list-style-position: outside;
$list-side-margin: 0;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// Callout
// -----------

$callout-background: $dark-gray;
$callout-background-fade: 0%;
$callout-border: 1px solid rgba($white, 0.3);
$callout-margin: 0 0 .5rem 0;
$callout-padding: .8rem 1rem;
$callout-font-color: $white;
$callout-font-color-alt: $white;
$callout-radius: $global-radius;
$callout-link-tint: 30%;

// Callout
// -----------

$closebutton-position: right top;
$closebutton-offset-horizontal: 1rem;
$closebutton-offset-vertical: 0.5rem;
$closebutton-size: 1em;
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;


@include foundation-global-styles;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-button-group;
@include foundation-forms;
@include foundation-callout;
@include foundation-close-button;
@include foundation-label;
@include foundation-visibility-classes;

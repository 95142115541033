@import 'foundation/backup';
@import 'icons';
@import 'lib/compass-mixins/lib/compass/css3/flexbox';
@import 'lib/compass-mixins/lib/compass/css3/appearance';
@import 'lib/compass-mixins/lib/compass/css3/user-interface';


// Core Style
// ---------
html, body, .wrapper {
  height: 100%;
  min-height: 100%;
}

body {
  background: url('/assets/images/mrex-back.jpg') no-repeat center center fixed;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  background-color: rgba(0, 0, 0,0.6);
  background-blend-mode: multiply;
}

.wrapper {
  @include display-flex;
  @include flex-direction(column);
  @include align-items(center);

  padding: {
    right: 1rem;
    left: 1rem;
  }

  > header, main {
    width: 100%;
    max-width: 1024px;
    @include breakpoint(medium) {
      &.expand {
        width: 700px;
        &.large { width: 800px }
      }
      width: 500px;
    }
  }

  > header {
    padding: 3rem 0;
    text-align: center;
    color: white;
    h1 {
      margin: 1rem 0;
      span {
        display: block;
        font-size:1.2rem;
      }
    }

  }

  section {
    &:not(:last-child) { padding-bottom: 1.5rem; }
    header h1 {
      color: $orange;
      font: {
        size: 1.2rem;
        weight: bold;
      }
    }
    h2 {
      color: $orange;
      font-size: 1rem;
      font-weight: 800;
    }
  }

  footer {
    width: 100%;
    border-top: 1px solid $dirty-white;
    text-align: center;
    margin-top: 5rem;
    padding-top: .5rem;
    p { font-size: .9rem }
    &.fix {
      position: fixed;
      bottom: 0;
      z-index: 1;
    }
    li {
      display: inline-block;
      padding: 0 2% 1% 2%;
      font-size: .9rem;
      span {
        display: block;
        font-size: .8rem;
      }
    }
  }
}

#terms, #about, #fees {
  nav {
    .selected {
      color: $orange;
      text-decoration: underline;
    }
  }

  h2 {
    color: $orange;
    font-size: 1rem;
    font-weight: 800;
  }

  span {
    display: block;
    font-size: .85rem;
    font-weight: 400;
  }

  h3, h4, h5 {
    color: $orange;
    font-size: 0.95rem;
    font-weight: 500;
  }

  &#fees {
    h2, h3, h4{
      font-size: 1.30rem;
      font-weight: 800;
    }

    table {
      text-align: center;
      width: 100%;
      margin: 1rem 0;
      background: rgba(255, 255, 255, 0.1);
      th, td {
        border: 1px solid $dirty-white;
        padding: .5rem .1rem;
        font-size: .95rem;
        span { font-size: .75rem; }
      }
    }
  }

  &#about {
    ul {
      li { margin-bottom: 1rem; }
      &.bullets li { margin-bottom: 0; }
    }
    h2 {
      border-bottom: 1px solid $dirty-white;
      font-size: 1.30rem;
      padding: .25rem 0;
    }
    h3 { margin-top: 1rem; }
    a { text-decoration: underline; }
    address { font-style: normal; }
  }
}

article {
  header h1 {
    color: $red;
    padding: 0 .5rem;
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: bold;
  }
}


// Foundation Style Overrides
// -------------------------
.label {
  background: transparent !important;
  white-space: inherit;
  font-size: 1rem;
  line-height: 1.6;
  &.success { color: $green; }
  &.alert { color: $red; }
}

.button {
  &.hollow { color: $white !important; }
  &.wrap {
    padding: 1.2em 1em;
    font-weight: 600;
    span {
      font: {
        size: .8rem;
        weight: normal;
      }
    }
  }
  &.mr-ripple {
    background-color: scale-color($red, $lightness: -20%);
    &:hover {
      background-color: $alert-color;
    }
  }
  &.secondary {
    &[disabled]:hover {
      background-color: $line-gray;
      color: $white;
    }
  }
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
textarea {
  border-bottom: 1px solid $white;
  &:focus { border-bottom: 2px solid $orange; }
  &:-webkit-autofill {
    -webkit-animation-name: transparentInput;
    -webkit-animation-fill-mode: both;
  }
  &::placeholder { color: $medium-gray; }
  &::-webkit-input-placeholder { color: $medium-gray }
}


// Custom Style
// -----------
.g-recaptcha { margin-bottom: .7rem; }

.mr-ripple-link {
  margin-top: 1rem;
  border: 1px solid $dirty-white;
  text-align: center;
  padding: {
    top: 2rem;
    bottom: 2rem;
  }
}

.wallet-list {
  margin: 0;
  li {
    @include flex-grid-row();
    @extend .align-middle;
    padding: 0.5rem 0.25rem;
    margin: 0.5rem auto;
    background: rgba(0,0,0,0.5);
    .currency {
      @include flex-grid-column(4, 0);
      text-align: left;
    }
    .wallet-details {
      @include flex-grid-column(8, 0);
      text-align: right;
      label {
        display: block;
        padding-right: 0;
        line-height: 1.2;
        &.alert { opacity: .6; }
      }
      p { margin-bottom: .5rem; }
    }

    .wallet-details-em {
      width: 100%;
      margin-top: 0.5em;
      span {
        background: $dirty-white;
        display: block;
        padding: 0.25em;
        text-align: center;
        color: $dashed-gray;
      }
    }

    input {
      border-bottom: 1px solid $line-gray !important;
      margin-bottom: .5rem;
      &:focus {
        border-bottom: 1px solid $alert-color !important;
      }
      &::-webkit-input-placeholder { font-size: .9rem; }
    }
  }

  .discard-txt {
    color: $red;
  }
}

.wallet-notes {
  list-style-type: initial;
  padding-left: 20px;
  a {
    color: $orange;
  }
}

.confirmation-list {
  h4 {
    font-weight: bold;
  }
  strong {
    color: $red;
    font-weight: bold;
  }
  .orange {
    color: $orange;
  }
  .red {
    color: $red;
  }
  ul {
    list-style-type: disc;
  }
}

.download-link {
  text-align: center;
  a {
    color: $orange;
    display: block;
    &:hover {
      color: $white;
    }
  }
}

@-webkit-keyframes transparentInput {
  to {
    color: $white;
    background: transparent;
  }
}


.withdrawalRequestForm, .profileForm {
  background-color: rgba($black, .6);
  padding: .5rem;
  margin-bottom: 2rem;
  button, p { margin-bottom: 0; }
  header {
    line-height: 0.5;
    text-align: center;
    position: relative;
    overflow-x: hidden;
    h2 {
      display: inline-block;
      position: relative;
      &:before, &:after {
        content: "";
        position: absolute;
        border-top: 1px solid $orange;
        top: 50%;
        width: 600px;
      }
      &:before {
        right: 100%;
        margin-right: 1.2rem;
      }
      &:after {
        left: 100%;
        margin-left: 1.2rem;
      }
    }
  }

  span.label { padding: 0; }
}


.discard-details {
  padding-left: 1rem;
  margin-bottom: 1rem;
  border: 1px solid $orange;
  background: rgba(0,0,0,0.5);
  .discard-iou {
    display: none;
    &.active { display: block; }
  }
  .discard-toggle .label { cursor: pointer; }
  .discard-reasons, .discard-toggle, .discard-iou {
    margin-bottom: 0 !important;
    padding-left: 1.3rem;
  }
  .discard-reasons {
    li {
      display: inline-flex;
      &:before {
        content: "-";
        margin-right: .5rem;
      }
    }
  }
  p { padding: 0; }
}

.discarded-currencies, .withdrawal-status {
  ul {
    color: $red;
    font-weight: bold;
    font-size: .85rem;
    padding-top: .5em;
  }
}

.summary-wrapper {
  font-size: 0.85rem;
  font-weight: 600;
  text-align: left;
  width: 90%;
  margin: 0 auto;
  ul li {
    span {
      display: inline-block;
      min-width: 10%;
    }
  }
  .summary-discards ul { color: $red; }
  .summary-withdraw ul { color:$green; }
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $black;
  background-color: rgba($black, 0.4);

  &.active {
    display: block;
  }
  .modal-content {
    background-color: $near-white;
    color: $line-gray;
    margin: 15% auto;
    padding: 20px;
    width: 35%;
    position: relative;
    min-width: 300px;
    max-width: 400px;
  }
  .modal-details {
    text-align: center;
    margin-bottom: 2rem;
  }
  .confirm-delete {
    header {
      margin-bottom: 1rem;
      img {
        height: 100px;
        width: 100px;
        opacity: 0.3;
      }
      h1 {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: 400;
        &.alert {
          color: $red;
        }
      }
      h3 {
        margin-bottom: 2rem;
        font-weight: 400;
      }
      span {
        display: block;
        font-size: 0.75em;
      }
    }
    p, li {
      font-size: 0.8em;
      text-align: left;
    }
    li {
      padding: 0 2rem;
      margin-bottom: 0.5rem;
      &:before {
        content: "*";
        float: left;
        margin-left: -10px;
      }
    }
  }

  .close {
    color: $line-gray;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    right: .7rem;
    top: -.2rem;
    &:hover, &:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .button-group, .button {
    margin-bottom: 0;
  }
}

time {
  font: {
    family: Avenir;
    size: 3.1rem;
  }
  text-align: center;
  display: block;
}

#code-wrap {
  @include display-flex;
  li {
    padding: .5rem;
    @include flex-direction(column);
    input[type=tel] {
      font-size: 2.5rem;
      height: 4rem;
      text-align: center;
    }
  }
}

.drag-drop {
  text-align: center;
  border: 2px dotted $dashed-gray;
  padding: 1rem 0;
  margin-bottom: 1rem;
  p, label, i {
    display: inline-block;
    margin: 0 .3rem;
  }
  i {
    font-size: 2.5rem;
    vertical-align: middle;
  }
  label {
    display: inline-block;
    cursor: pointer;
    background-color: $orange;
    color: $white;
    padding: .4rem .8rem;
    &:hover {
      background-color: $red;
    }
  }
  input[type='file'] {
    display:none;
  }
  &.disabled {
    color: $light-gray;
    label {
      color: $medium-gray;
      background-color: $near-white;
    }
  }
  &.error {
    background-color: #ffd9d9 !important;
    color: red !important;
  }
}

.document {
  padding: 1em 0;
}

.callout {
  margin: 1em;
  &.success {
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

img.default-thumbnail {
  border: 1px solid $orange;
  background: $dirty-white;
  @include breakpoint(small) {
    width: 240px;
  }
}

.photo {
  position: relative;
  width: 80%;
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  background-color: $dirty-white;
  border: 1px dotted $dashed-gray;
  .cancel-button {
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    span {
      padding: .25rem .4rem;
      border: 1px solid $dashed-gray;
      background: $black;
      border-radius: 50%;
    }
  }
}

.bullets {
  list-style-type: disc;
  margin: 1.5rem 0 1.5rem 1.5rem;
}

.sub {
  font-size: .8rem;
  color: $dashed-gray;
  font-weight: 500;
  i { font-size: .6rem;}
  &.medium {
    font-size: .9rem;
  }
  &.primary {
    color: $orange !important;
  }
}

select {
  background-color: $line-gray;
}

fieldset {
  width: 100%;
}

#personalProfile {
  label {
    font-size: 0.68rem;
  }
}

.load-modal-submit {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  }
.modal-content-submit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0c0c0c;
    padding: 1rem 1.5rem;
    width: 24rem;
    border-radius: 0.5rem;
    text-align: center;
    opacity:0.9;
}
.close-btn {
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background: $black;
    margin-right: -14px;
    margin-top: -7px;
}
.close-btn:hover {
    background-color: $orange;
}
.hide {
  visibility:hidden;
}
.show-modal-submit {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

#msg {
  color: $orange;
}

#modal-img {
  height: 150px;
}
